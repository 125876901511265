import React from "react";
import "./TermsConditions.scss";

const TermsConditions = () => {
  return (
    <div className="terms-container">
      <h1>Terms and Conditions</h1>
      <br />
      <p>
        By using the Krishna Chaitanya Educare’s School ERP website classerp.in,
        you agree to be bound by these terms of service. Please read these terms
        carefully before using the Service.
      </p>

      <br />
      <h3>1. Use of the Service</h3>
      <p>
        You agree to use the Service only for lawful purposes. You are
        responsible for ensuring that all information you provide is accurate
        and up to date.
      </p>
      <br />

      <h3>2. Payment Terms</h3>
      <p>
        All payments made through the Service are subject to our payment
        gateway's policies. You are responsible for paying all applicable taxes
        and fees related to your transactions.
      </p>
      <br />

      <h3>3. User Accounts</h3>
      <p>
        To use the Service, you must create an account. You are responsible for
        maintaining the security of your account and for all activities that
        occur under your account.
      </p>
      <br />

      <h3>4. Modifications to the Service</h3>
      <p>
        We reserve the right to modify or discontinue the Service, temporarily
        or permanently, with or without notice.
      </p>
      <br />

      <h3>5. Limitation of Liability</h3>
      <p>
        We are not responsible for any indirect, incidental, or consequential
        damages resulting from your use of the Service.
      </p>
      <br />

      <h3>6. Termination</h3>
      <p>
        We reserve the right to suspend or terminate your access to the Service
        at any time for any reason.
      </p>
      <br />

      <h3>7. Governing Law</h3>
      <p>
        These terms are governed by the laws of Maharashtra, India, without
        regard to its conflict of laws principles.
      </p>
      <br />
      <h3>8. Refund Policy</h3>
      <p>No refund will be issued once the payment is made.</p>
      <br />

      <h3>Contact Us</h3>
      <p>
        If you have any questions about these Terms and Conditions, please
        contact us at info@chaitanyasacademy.in or +91 7003419670.
      </p>
    </div>
  );
};

export default TermsConditions;
