import React, { useState } from "react";
import axios from "axios";
import "./VerificationComponent.scss";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const VerificationComponent = ({ type, setVerified, setValue }) => {
  const [input, setInput] = useState("");
  const [otp, setOtp] = useState("");
  const [step, setStep] = useState(1);
  const [error, setError] = useState("");

  const sendOtp = async () => {
    try {
      await axios.post(`${BE_URL}/verification/send-otp`, {
        type,
        value: input,
      });
      setStep(2);
    } catch (err) {
      console.log(err);
      if (err.response.status == 409) {
        setError("This record already exists!");
      } else {
        setError("Something went wrong! Please try again.");
      }
    }
  };

  const verifyOtp = async () => {
    try {
      const response = await axios.post(`${BE_URL}/verification/verify-otp`, {
        type,
        value: input,
        otp,
      });
      if (response.data.verified) {
        setVerified(true);
        setValue(input);
      } else {
        setVerified(false);
        setError("Invalid OTP. Please try again.");
      }
    } catch (err) {
      setError("Error verifying OTP. Please try again.");
    }
  };

  return (
    <div className="verification-container">
      <h1>Welcome to Chaitanya's Academy</h1>
      <div className="verification-container-title">
        Please verify your {type} to proceed.
      </div>
      {step === 1 ? (
        <div className="input-container">
          {type === "email" ? (
            <>
              {/* <label>{"Email"}</label> */}
              <input
                type="email"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder={"Enter your email"}
              />
            </>
          ) : (
            <>
              {/* <label>{"Whatsapp"}</label> */}
              <input
                type="number"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder={"Enter your WhatsApp number"}
              />
            </>
          )}

          <button onClick={sendOtp}>Send OTP</button>
        </div>
      ) : (
        <div className="input-container">
          <label>OTP</label>
          <input
            type="text"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            placeholder="Enter the OTP"
          />
          <button onClick={verifyOtp}>Verify OTP</button>
        </div>
      )}
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default VerificationComponent;
