import React, { useState, useEffect } from "react";
import "./Timetables.scss"; // Your CSS file for styling
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import { useSelector } from "react-redux";
// import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
// import TimetableDetails from "../../components/TimetableDetails/TimetableDetails"; // Assuming you have this component
import Button from "../../components/Button/Button";
import HalfList from "../../components/HalfList/HalfList";
import HalfDetails from "../../components/HalfDetails/HalfDetails";
import ObjectDropDown from "../../components/ObjectDropDown/ObjectDropDown";
import { useNavigate } from "react-router-dom";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import CustomDropDown from "../../components/CustomDropDown/CustomDropDown";
import Modal from "../../components/Modal/Modal";
import ResponseModal from "../../components/ResponseModal/ResponseModal";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const Timetables = () => {
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Students")
    ?.dropdowns.find((dd) => dd.name === "Timetables")?.permissions;

  const [selectedItem, setSelectedItem] = useState(null);
  const [timetables, setTimetables] = useState([]);
  const [classes, setclasses] = useState([]);
  const [divisions, setdivisions] = useState([]);
  const [class_id, setclass_id] = useState(null);
  const [division_id, setdivision_id] = useState(null);
  const [class_name, setclass_name] = useState(null);
  const [division_name, setdivision_name] = useState(null);
  const [academic_year, setacademic_year] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);
  const [isAddModalOpen, setisAddModalOpen] = useState(false);

  useEffect(() => {
    fetchTimetables();
    fetchClasses();
    // fetchdivisions();
  }, []);

  // Update fetchClasses to include fetchDivisionsForClass call on class selection change

  useEffect(() => {
    if (selectedItem) {
      setacademic_year(selectedItem.academic_year);
      setclass_name(selectedItem.class_name);
      //   setclass_id(selectedItem.class_id);
      setdivision_name(selectedItem.division_name);
      console.log("Selected item changed ", selectedItem);
    }
  }, [selectedItem]);

  const fetchTimetables = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(`${BE_URL}/api/timetables`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });
      setTimetables(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return fetchTimetables(); // Retry fetching subjects with new token
        } else {
          // Redirect to login or show an error
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setError(error.message || "Failed to fetch timetables.");
      }
      console.error("Error fetching timetables:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchClasses = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens")); // Ensure you have tokens stored in localStorage
      const response = await axios.get(`${BE_URL}/api/classes`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`, // Use the access token for authorization
        },
      });
      setclasses(response.data); // Assuming the API returns an array of class objects
    } catch (error) {
      console.error("Error fetching classes:", error);
      // Handle specific errors (e.g., unauthorized, server issues)
      if (error.response && error.response.status === 401) {
        // Handle unauthorized error, e.g., redirect to login or refresh token
        alert("Session expired. Please login again.");
      } else {
        alert("Failed to fetch classes. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdd = async (newTimetable) => {
    setIsLoading(true);
    setError(null);
    setisAddModalOpen(true);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        `${BE_URL}/api/timetables`,
        newTimetable,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );
      fetchTimetables();
      setresponseType("Success");
      setmsg("Added Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleAdd(newTimetable); // Retry adding subject with new token
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to add. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
      setisAddModalOpen(false);
    }
  };

  const handleEdit = async () => {
    if (!class_id || !division_id || !academic_year) {
      alert("All fields are mandatory");
      return;
    }
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.put(
        `${BE_URL}/api/timetables/${selectedItem.id}`,
        { class_id, division_id, academic_year },
        { headers: { Authorization: `Bearer ${tokens.accessToken}` } }
      );
      alert("Timetable updated successfully!");
      fetchTimetables(); // Refresh the list of timetables
      setresponseType("Success");
      setmsg("Updated Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Attempt to refresh token
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          handleEdit(); // Retry the update with the new token
        } else {
          setError("Session expired. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to edit. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        "https://server.classerp.in/refreshToken",
        { token: tokens.refreshToken }
      );

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data.accessToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("Session expired. Please login again.");
      // Navigate to login page if needed
      return null;
    }
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      await axios.delete(`${BE_URL}/api/timetables/${selectedItem.id}`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      setSelectedItem(null);
      fetchTimetables();

      setresponseType("Success");
      setmsg("Deleted Successfully");
      setisResponseOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleDelete(id);
        } else {
          alert("Authentication failed. Please log in again.");
        }
      } else {
        setresponseType("Error");
        setmsg("Failed to delete. Please try again later.");
        setisResponseOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="timetables">
      {isLoading && <Loader />}
      {permissions?.can_read ? (
        <>
          <Modal
            isOpen={isResponseOpen}
            onClose={() => setisResponseOpen(false)}
          >
            <ResponseModal
              type={responseType}
              msg={msg}
              onClick={() => setisResponseOpen(false)}
            />
          </Modal>
          <div className="timetables-header">
            <div className="timetables-header-name">Timetables</div>
            <div className="timetables-header-info"></div>
          </div>
          <div className="timetables-contents">
            <div className="timetables-contents-list">
              <HalfList
                data={timetables}
                fields={["class_name", "division_name", "academic_year"]}
                setSelectedItem={setSelectedItem}
                handleAdd={handleAdd}
                isAddModalOpen={isAddModalOpen}
                can_create={permissions?.can_create}
                elem={
                  <AddTimetable
                    handleAdd={(newTimetable) => handleAdd(newTimetable)}
                    classes={classes}
                    divisions={divisions}
                  />
                }
              />
              {error && <p className="error">{error}</p>}
            </div>
            <div className="timetables-contents-details">
              {selectedItem ? (
                <div>
                  <HalfDetails
                    id={selectedItem.id}
                    name={`TimeTable`}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    can_delete={permissions?.can_delete}
                    can_update={permissions?.can_update}
                  >
                    <DetailsChild
                      id={selectedItem.id}
                      class_name={class_name}
                      division_name={division_name}
                      setclass_name={setclass_name}
                      setdivision_name={setdivision_name}
                      setclass_id={setclass_id}
                      setdivision_id={setdivision_id}
                      classes={classes}
                      divisions={divisions}
                      academic_year={academic_year}
                      setacademic_year={setacademic_year}
                    ></DetailsChild>
                  </HalfDetails>
                </div>
              ) : (
                <div className="timetables-contents-details-noselection">
                  Select an item to see details
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Timetables;

const AddTimetable = ({ handleAdd, classes }) => {
  const [division_name, setdivision_name] = useState(null);
  const [class_id, setclass_id] = useState(null);
  const [division_id, setdivision_id] = useState(null);
  const [class_val, setclass_val] = useState(null);
  const [academic_year, setacademic_year] = useState(null);
  const [divisions, setdivisions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log("rendiering");
    if (class_id) {
      console.log("rendiering 2");

      setdivision_name(null);
      setdivision_id(null);
      fetchDivisionsForClass(class_id);
    }
  }, [class_id]);

  const fetchDivisionsForClass = async (classId) => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(
        `${BE_URL}/api/class/${classId}/divisions`,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );
      setdivisions(response.data);
    } catch (error) {
      alert("Failed to fetch divisions");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = () => {
    if (!division_id || !class_id) {
      alert("Class and Divisions is mandatory!");
      return;
    }
    const newTimetable = {
      class_id,
      division_id,
      academic_year,
    };
    handleAdd(newTimetable);
  };
  console.log(classes);

  return (
    <div className="halfAdd">
      <div className="halfAdd-title">Add a Timetable</div>
      <CustomDropDown
        label="Select Class"
        data={classes.map(({ class_name, id }) => ({
          id: id,
          name: class_name.toString(),
        }))}
        value={class_id}
        searchable={false}
        onChange={setclass_id}
        // setValue={setclass_val}
      />
      {/* <ObjectDropDown
        label="Select Class"
        options={classes.map(({ class_name, id }) => ({
          label: id,
          value: class_name.toString(),
        }))}
        value={class_val}
        setId={setclass_id}
        setValue={setclass_val}
      /> */}
      {class_id && divisions && (
        <ObjectDropDown
          label=" Select Division"
          options={divisions.map(({ division_name, id }) => ({
            label: id,
            value: division_name.toString(),
          }))}
          value={division_name}
          setId={setdivision_id}
          setValue={setdivision_name}
        />
      )}
      <CustomTextInput
        value={academic_year}
        label={`Academic Year`}
        placeholder={`Academic Year`}
        onChange={(e) => setacademic_year(e.target.value)}
      />
      <Button text="Add TimeTable" onClick={handleSubmit} />
    </div>
  );
};

const DetailsChild = ({
  id,
  class_name,
  division_name,
  setclass_name,
  setdivision_name,
  setclass_id,
  setdivision_id,
  classes,
  divisions,
  academic_year,
  setacademic_year,
}) => {
  const navigate = useNavigate();

  return (
    <div className="dc">
      <div className="dc-visit">
        <Button
          text={`View Details`}
          onClick={() => navigate(`/admin/timetables/${id}`)}
        />
      </div>
      <div className="dc-input">
        <ObjectDropDown
          label="Class"
          options={classes.map(({ class_name, id }) => ({
            label: id,
            value: class_name.toString(),
          }))}
          value={class_name}
          setId={setclass_id}
          setValue={setclass_name}
        />
      </div>
      <div className="dc-input">
        <ObjectDropDown
          label="Division"
          options={divisions.map(({ division_name, id }) => ({
            label: id,
            value: division_name.toString(),
          }))}
          value={division_name}
          setId={setdivision_id}
          setValue={setdivision_name}
        />
      </div>
      <div className="dc-input">
        <CustomTextInput
          value={academic_year}
          label={`Academic Year`}
          onChange={(e) => setacademic_year(e.target.value)}
        />
      </div>
    </div>
  );
};
