import React from "react";
import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <h1>Privacy Policy</h1>
      <br />
      <p>
        Your privacy is important to us. It is Krishna Chaitanya Educare’s
        policy to respect your privacy regarding any information we may collect
        from you across our website, classerp.in, and other sites we own and
        operate.
      </p>

      <br />
      <h3>1. Information we collect</h3>
      <p>
        We collect personal information such as your name, email address, and
        other relevant data when you register on our site or fill out a form. We
        may also collect information related to the transactions you perform
        using our platform.
      </p>

      <br />
      <h3>2. How we use your information</h3>
      <p>We use the information we collect in the following ways:</p>
      <ul>
        <li>To provide you with access to the ERP system</li>
        <li>To process your payments</li>
        <li>To communicate with you regarding your account or services</li>
        <li>To improve our website and services based on your feedback</li>
      </ul>

      <br />
      <h3>3. Sharing your information</h3>
      <p>
        We do not share your personal information with third parties except in
        the following cases:
      </p>
      <ul>
        <li>If required by law</li>
        <li>
          If necessary to protect our rights or comply with legal processes
        </li>
      </ul>

      <br />
      <h3>4. Security of your information</h3>
      <p>
        We take appropriate security measures to protect your data against
        unauthorized access, disclosure, or alteration. However, no method of
        transmission over the internet or method of electronic storage is
        completely secure.
      </p>

      <br />
      <h3>5. Your rights</h3>
      <p>You have the right to:</p>
      <ul>
        <li>Access the personal information we hold about you</li>
        <li>Request correction of any inaccurate information</li>
        <li>Request deletion of your personal data</li>
      </ul>

      <br />
      <h3>6. Changes to this policy</h3>
      <p>
        We may update this policy from time to time. When we do, the same will
        be reflected here on this page. You may always find our updated policies
        on this page.
      </p>

      <br />
      <h3>Contact Us</h3>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at info@chaitanyasacademy.in or +91 7003419670.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
