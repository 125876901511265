import "./Login.scss";
import loginbg from "../../assets/images/loginbg.png";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { clearUser, setUser } from "../../state/auth/authSlice";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import Button from "../../components/Button/Button";

// const BE_URL = `http://localhost:8000`;
const BE_URL = `https://server.classerp.in`;

const Login = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showResend, setShowResend] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const userData = localStorage.getItem("ERPTokens");
    if (userData) {
      localStorage.removeItem("ERPTokens");
      dispatch(clearUser());
    }
  }, []);

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      isOtpSent ? handleVerifyOtp() : handleRequestOtp();
    }
  };

  const handleRequestOtp = () => {
    setShowResend(false);
    setIsLoading(true);
    axios
      .post(`${BE_URL}/api/auth/login`, { email })
      .then(() => {
        setIsOtpSent(true);
        setIsLoading(false);
        alert("OTP sent to your email.");

        setTimeout(() => setShowResend(true), 30000);
      })
      .catch((error) => {
        console.error("Error requesting OTP:", error);
        setIsLoading(false);
        alert("Error requesting OTP, please try again.");
      });
  };

  const handleVerifyOtp = () => {
    setIsLoading(true);
    axios
      .post(`${BE_URL}/api/auth/verify-otp`, { email, otp })
      .then((response) => {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data.tokens));
        const obj = jwtDecode(response.data.tokens.accessToken);
        const { name, user_id, email, role_id } = obj;
        const user = { user_id, name, email, role_id };

        dispatch(setUser(user));
        setIsLoading(false);
        if (role_id === 10) {
          navigate("/student");
        } else {
          navigate("/admin");
        }
      })
      .catch((error) => {
        console.error("Error verifying OTP:", error);
        setIsLoading(false);
        alert("Invalid OTP, please try again.");
      });
  };

  return (
    <div className="login" style={{ backgroundImage: `url(${loginbg})` }}>
      {isLoading && <Loader />}
      {/* <Modal isOpen={isModalOpen} onClose={() => setisModalOpen(false)}>
        <ResponseModal
          type={responseType}
          msg={msg}
          onClick={() => setisResponseOpen(false)}
        />
      </Modal> */}
      <div className="login-form">
        <div className="login-form-header">LOGIN</div>
        <input
          type="email"
          placeholder="EMAIL"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={handleEnter}
        />
        {isOtpSent && (
          <input
            type="text"
            placeholder="OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            onKeyDown={handleEnter}
          />
        )}
        <Button
          text={isOtpSent ? "Verify OTP" : "Request OTP"}
          type={`primary`}
          onClick={isOtpSent ? handleVerifyOtp : handleRequestOtp}
        />
        {isOtpSent && showResend && (
          <div className="login-form-forgot" onClick={handleRequestOtp}>
            Resend OTP
          </div>
        )}
        <div className="login-form-nouser">Not a user? Contact Admin.</div>
      </div>
    </div>
  );
};

export default Login;
