import React, { useEffect, useState } from "react";
import axios from "axios";
import "./FeesInformation.scss";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const FeesInformation = () => {
  const [installments, setInstallments] = useState([]);
  const [overdueSummary, setOverdueSummary] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedInstallments, setSelectedInstallments] = useState([]);
  const [emailContent, setEmailContent] = useState("");
  const [filters, setFilters] = useState({
    paymentType: "Overdue",
    dateRange: "today",
  });

  const tokens = JSON.parse(localStorage.getItem("ERPTokens"));

  // Fetch overdue installment summary
  const fetchOverdueSummary = async () => {
    try {
      const response = await axios.get(
        `${BE_URL}/api/feeDetails/installments/overdue`,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );
      setOverdueSummary(response.data);
    } catch (error) {
      console.error("Failed to fetch overdue summary", error);
    }
  };

  // Fetch filtered installments
  const fetchInstallments = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BE_URL}/api/feeDetails/installments`,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
          params: filters,
        }
      );
      setInstallments(response.data);
    } catch (error) {
      console.error("Failed to fetch installments", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchOverdueSummary();
    fetchInstallments();
  }, [filters]);

  const handleFilterChange = (field, value) => {
    setFilters({
      ...filters,
      [field]: value,
    });
  };

  const handleInstallmentSelect = (id) => {
    setSelectedInstallments((prev) =>
      prev.includes(id) ? prev.filter((instId) => instId !== id) : [...prev, id]
    );
  };

  const handleSendEmails = async () => {
    try {
      await axios.post(
        `${BE_URL}/api/feeDetails/installments/send-emails`,
        {
          installmentIds: selectedInstallments,
          emailContent,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );
      alert("Emails sent successfully!");
    } catch (error) {
      console.error("Failed to send emails", error);
      alert("Failed to send emails, please try again.");
    }
  };

  return (
    <div className="feesinfo">
      <div className="feesinfo-title">Fees Information</div>

      {/* Overdue Summary Section in Cards */}

      <div className="feesinfo-overdue">
        <div className="feesinfo-overdue-title">Overdues</div>
        <div className="feesinfo-overdue-cards">
          {overdueSummary.map((data, index) => (
            <div key={index} className="feesinfo-overdue-cards-card">
              <h3>{data.account ? data.account : "Uncategorized"}</h3>
              <p>{data.overdue_count} overdue installments</p>
              <p>Total Pending: Rs. {data.total_pending}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Filters */}
      <div className="filters">
        <select
          onChange={(e) => handleFilterChange("paymentType", e.target.value)}
        >
          <option value="Overdue">Overdue</option>
          <option value="Upcoming">Upcoming</option>
        </select>

        <select
          onChange={(e) => handleFilterChange("dateRange", e.target.value)}
        >
          {filters.paymentType === "Overdue" ? (
            <>
              <option value="all">All</option>
              <option value="yesterday">Yesterday</option>
              <option value="1_week">1 Week</option>
              <option value="1_month">1 Month</option>
              <option value="2_months_plus">2 Months +</option>
            </>
          ) : (
            <>
              <option value="today">Today</option>
              <option value="tomorrow">Tomorrow</option>
              <option value="1_week">1 Week</option>
              <option value="1_month">1 Month</option>
            </>
          )}
        </select>
      </div>

      {/* Installments Table */}
      <InstallmentsTable
        installments={installments}
        loading={loading}
        onSelectInstallment={handleInstallmentSelect}
        selectedInstallments={selectedInstallments}
        setSelectedInstallments={setSelectedInstallments}
      />

      {/* Email Section */}
      <div className="email-section">
        <textarea
          value={emailContent}
          onChange={(e) => setEmailContent(e.target.value)}
          placeholder="Enter custom email content"
        />
        <button onClick={handleSendEmails}>Send Email</button>
      </div>
    </div>
  );
};

export default FeesInformation;

// Function to format the due date in a readable format
const formatDueDate = (dueDate) => {
  const date = new Date(dueDate);
  return date.toLocaleDateString("en-IN", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const InstallmentsTable = ({
  installments,
  loading,
  onSelectInstallment,
  selectedInstallments,
  setSelectedInstallments,
}) => {
  const [selectAll, setSelectAll] = useState(false);

  // Handle select all checkbox
  const handleSelectAll = () => {
    if (selectAll) {
      // Uncheck all if already selected
      setSelectAll(false);
      setSelectedInstallments([]); // Clear all selected installments
    } else {
      // Select all
      setSelectAll(true);
      setSelectedInstallments(installments.map((inst) => inst.id)); // Add all installment IDs to the selectedInstallments state
    }
  };

  // Handle individual checkbox selection
  const handleIndividualSelect = (id) => {
    onSelectInstallment(id);
  };

  // Calculate total installments count and total amount
  const totalInstallments = installments.length;
  const totalAmount = installments.reduce(
    (acc, installment) => acc + Number(installment.amount),
    0
  );

  if (loading) return <div>Loading...</div>;

  if (installments.length === 0) return <div>No installments available.</div>;

  return (
    <div className="installments-table-wrapper">
      <table className="installments-table">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
              />
              Select All
            </th>
            <th>Student Roll No</th>
            <th>Installment Name</th>
            <th>Amount</th>
            <th>Due Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {installments.map((inst) => (
            <tr key={inst.id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedInstallments.includes(inst.id)}
                  onChange={() => handleIndividualSelect(inst.id)}
                />
              </td>
              <td>{inst.student_roll_no}</td>
              <td>{inst.installment_name}</td>
              <td>Rs. {inst.amount}</td>
              <td>{formatDueDate(inst.due_date)}</td>
              <td>{inst.status}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Display Total Count and Sum at the Bottom */}
      <div className="installments-summary">
        <p>
          <strong>Total Installments:</strong> {totalInstallments}
        </p>
        <p>
          <strong>Total Amount:</strong> Rs. {totalAmount.toFixed(2)}
        </p>
      </div>
    </div>
  );
};
